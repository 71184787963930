import { Children } from 'react';
import { ILanguageSelector } from './languageSelector.models';
import * as S from './languageSelector.style';
import Flags from '../Flags';
import { useLanguageSelector } from './languageSelector.hook';
import { Svg } from '@vfit/shared/atoms';

const LanguageSelector = ({ onSelectLanguage, isOpenSearch }: ILanguageSelector) => {
  const {
    allOtherLangs,
    isMobile,
    isDesktop,
    isOpenLangSelectWindow,
    heightLangNavMenu,
    navRef,
    onSelectLang,
    selectedLang,
    setIsOpenLangSelectWindow,
  } = useLanguageSelector({ onSelectLanguage, isOpenSearch });
  const isEnabledMultiLang = process.env['NEXT_PUBLIC_ENABLE_MULTILANGUAGE'] === 'TRUE';

  if (allOtherLangs?.length < 1 || !isEnabledMultiLang) return null;

  return (
    <S.LanguageSelectorContainer
      onClick={() => setIsOpenLangSelectWindow(!isOpenLangSelectWindow)}
      isDesktop={isDesktop}
    >
      <S.Wrapper>
        <S.MainContainer isDesktop={isDesktop}>
          <S.Container isDesktop={isDesktop}>
            <Flags
              flag={selectedLang.id}
              width={isMobile ? 24 : 32}
              height={isMobile ? 24 : 32}
              circle
            />
            <h4>{selectedLang.value?.toUpperCase()}</h4>
            {isOpenLangSelectWindow ? <Svg name='chevronUp' /> :  <Svg name='chevronDown' />}
            <S.NavigateLang
              isDesktop={isDesktop}
              ref={navRef}
              height={heightLangNavMenu}
              isVisible={isOpenLangSelectWindow}
            >
              <h5>Scegli la lingua</h5>
              {Children.toArray(
                allOtherLangs?.map((el) => (
                  <button type="button" onClick={() => onSelectLang(el.id)}>
                    <Flags
                      flag={el?.id}
                      width={isMobile ? 24 : 32}
                      height={isMobile ? 24 : 32}
                      circle
                    />
                    <h6>{el.value?.toUpperCase()}</h6>
                  </button>
                ))
              )}
            </S.NavigateLang>
          </S.Container>
        </S.MainContainer>
      </S.Wrapper>
    </S.LanguageSelectorContainer>
  );
};

export default LanguageSelector;
