import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { trackLink, useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ILanguageSelector } from './languageSelector.models';
import { getLocalLanguage, switchLang } from './languageSelector.utils';

const useLanguageSelector = ({ onSelectLanguage, isOpenSearch }: ILanguageSelector) => {
  const queryClient = useQueryClient();
  const languages: { id: string; value: string; isSelected?: boolean }[] =
    queryClient.getQueryData<[{ id: string; value: string }]>('languages') || [];
  languages.unshift({ id: 'it', value: 'IT' });
  const navRef = useRef<HTMLElement>(null);
  const [allLangs, setAllLangs] = useState(switchLang(languages, getLocalLanguage() || ''));
  const [isOpenLangSelectWindow, setIsOpenLangSelectWindow] = useState(false);
  const [selectedLang, ...allOtherLangs] = allLangs;
  const [heightLangNavMenu, setHeightLangNavMenu] = useState('0px');
  const { isMobile, isDesktop } = useDeviceType();
  const { trackWithNewDatalayer } = useTracking({ event: ['home'] });

  const onSelectLang = (id: string) => {
    setAllLangs(switchLang(allLangs, id));
    navRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    onSelectLanguage?.(id);
    const upDatedLang = allLangs.find((l) => l.id === id)?.id || '';
    localStorage.setItem('lang', upDatedLang);
    trackLink(`lang:${upDatedLang}`);
  };

  useEffect(() => {
    trackWithNewDatalayer({
      event: ['home'],
      page_country: selectedLang.id?.toUpperCase() || 'IT',
      page_language: selectedLang.id || 'IT',
      page_locale: selectedLang.id
        ? `${selectedLang.id}-${selectedLang.id?.toUpperCase()}`
        : 'it-IT',
    });
  }, [selectedLang.id]);

  useLayoutEffect(() => {
    if (navRef.current) setHeightLangNavMenu(`${navRef.current.scrollHeight}px`);
  }, []);

  useEffect(() => {
    isOpenSearch && setIsOpenLangSelectWindow(false);
  }, [isOpenSearch]);

  return {
    allOtherLangs,
    isMobile,
    isDesktop,
    isOpenLangSelectWindow,
    heightLangNavMenu,
    navRef,
    selectedLang,
    setIsOpenLangSelectWindow,
    onSelectLang,
  };
};

export { useLanguageSelector };
