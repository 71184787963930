/* stylelint-disable rule-empty-line-before */
import styled from 'styled-components';

export const FlagsContainer = styled.div<{ width?: number; height?: number; border?: boolean }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  svg {
    border-radius: ${(props) => (props.border ? '50%' : '0%')};

    /* On Safari, you need to specify that it should be as large as the container,
       otherwise the svg won't be visible */
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.height}px`};
  }
`;
