import { checkWindow } from '@vfit/shared/data-access';

const getParamsInUrl = (key: string): string => {
  if (!checkWindow()) {
    return '';
  }
  const searchParams =
    window.location && window.location.href ? new URL(window.location.href).searchParams : '';
  return searchParams ? searchParams?.get(key) || '' : '';
};

const switchLang = (
  allLangs: { id: string; value: string; isSelected?: boolean }[],
  id: string
) => {
  const orgLangs = allLangs?.filter((el) => el.id !== id);
  const foundedLang = allLangs.find((el) => el.id === id);
  if (foundedLang) orgLangs.unshift(foundedLang);
  return orgLangs;
};

/**
 * Method to retrieve the local language
 * from local storage and query string
 */
const getLocalLanguage = (): string => {
  if (!checkWindow()) {
    return 'it';
  }
  const queryStringLang = getParamsInUrl('lang');
  const localLang = localStorage.getItem('lang');
  // const browserLang = checkWindow() ? window.navigator?.language?.split('-')?.[0] || '' : '';
  return queryStringLang || localLang || 'it';
};

export { switchLang, getLocalLanguage };
