import React, { useState } from 'react';
import { ConfigMenu, INavigationMenu } from '@vfit/shared/models';
import { getHome } from '@vfit/shared/data-access';

export const useMenu = () => {
  const [menuState, setMenuState] = useState<ConfigMenu>({
    isHovered: false,
    menuIndex: 0,
  });

  return { menuState, setMenuState };
};

export const handlerClickMenu = (
  evt: React.MouseEvent<HTMLAnchorElement>,
  menu: INavigationMenu
) => {
  const home = getHome();
  evt.preventDefault();
  if (!menu.links) {
    if (!menu.url || menu.url === '/') {
      window.location.href = '/';
    } else if (menu?.url?.[0] === '/') {
      window.open(`${home}${menu.url}`, '_self');
    } else {
      window.open(menu.url, '_self');
    }
  }
};
