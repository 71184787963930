import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const LanguageSelectorContainer = styled.div<{ isDesktop: boolean }>`
  box-sizing: border-box;
  margin: 19px 0;
  ${(p) => p.isDesktop && 'border-left: 1px solid black;'}
`;

export const Container = styled.div<{ isDesktop: boolean }>`
  box-sizing: border-box;
  ${(p) => p.isDesktop && 'position: relative;'}
  gap: 8px;

  // safari
  & > h4 {
    margin-right: 0 8px;
  }
`;

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  z-index: 2;
  margin-left: 32px;
  gap: 24px;
`;

export const MainContainer = styled.main<{ isDesktop: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 161px;
  ${(p) =>
    !p.isDesktop &&
    `
    width: 107px;
    margin-right: 40px;
    border-right: 1px solid black;
    position: relative;
  `}

  div,
  h4 {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0;
  }

  h4 {
    color: ${colors.$0d0d0d};
    font-family: 'Vodafone Rg', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`;

export const NavigateLang = styled.nav<{ isVisible?: boolean; height: string; isDesktop: boolean }>`
  box-sizing: border-box;
  top: calc(100% + ${(p) => (p.isDesktop ? '22' : '16')}px);
  ${(p) => (p.isDesktop ? 'left: 50%' : 'right: 0')};
  ${(p) => p.isDesktop && 'transform: translateX(-50%);'}
  overflow: hidden;
  background-color: #fff;
  padding: ${(p) => (p.isVisible ? '24px 0 0' : '0')};
  max-height: ${(p) => (p.isVisible ? `calc(${p.height} + 24px)` : '0')};
  transition: max-height 0.3s ease-in-out;
  ${(p) => !p.isDesktop && '&& {width: 161px;}'}

  && {
    position: absolute;
  }

  h5 {
    color: #000;
    font-family: Vodafone, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    padding: 0 24px;
  }

  button {
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 8px;
    height: 80px;
    width: 100%;
    justify-content: center;
    padding: 0;
    transition: all 0.3s;

    &:hover {
      background-color: #f7f7f7;
    }

    h6 {
      color: ${colors.$0d0d0d};
      text-align: center;
      font-family: 'Vodafone Rg', serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin: 0;

      // safari
      margin-left: 8px;
    }
  }
`;
