import React, { Children, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { HamburgerMenu, Logo, Search, User } from '@vfit/shared-icons';
import { LanguageSelector } from '@vfit/shared/components';
import { ActionsMenu, ActionsMenuList, IconButton, LogoWrapper, MainContainer } from './head.style';
import { IconsAction, IHead } from './head.model';
import SearchBox from '../../../components/SearchBox/SearchBox';
import BlackHeader from '../../../components/Head/head';

const icons: IconsAction = {
  search: <Search />,
  profile: <User />,
  hamburgerMenu: <HamburgerMenu />,
};

const Head: React.FC<IHead> = ({ actionsMenu, openMenu, homeRedirect, customerType, onTranslate, disableBlackHead }) => {
  const { isTablet, isMobile } = useDeviceType();
  const [isOpenSearch, setIsOpenSearch] = useState(false);

  return (
    <>
      {disableBlackHead !== 'true' && (
        <BlackHeader customerType={customerType || []} otherInfo={[]} />
      )}
      <MainContainer>
        <LogoWrapper>
          <a href={homeRedirect}>
            <Logo />
          </a>
        </LogoWrapper>
        <ActionsMenu>
          <LanguageSelector
            onSelectLanguage={(idLang) => onTranslate?.(idLang)}
            isOpenSearch={isOpenSearch}
          />
          <SearchBox setIsOpenSearch={setIsOpenSearch} />
          {Children.toArray(
            actionsMenu?.map((action) => {
              if (!action.url || !icons[action?.category || '']) return null;
              return (
                <ActionsMenuList>
                  <a href={action.url} aria-label={action.category}>
                    {icons[action.category]}
                  </a>
                </ActionsMenuList>
              );
            })
          )}
          {(isTablet || isMobile) && (
            <IconButton aria-label="menu" onClick={() => openMenu('fade-in')}>
              <HamburgerMenu />
            </IconButton>
          )}
        </ActionsMenu>
      </MainContainer>
    </>
  );
};

export default Head;
