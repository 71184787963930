import styled from 'styled-components';
import { breakpoints, pxToRem } from '@vfit/shared/themes';

export const WrapperContainerLite = styled.header<{ pt?: number; disablePaddingTop: boolean }>`
  + div {
    padding-top: ${({ disablePaddingTop }) => (disablePaddingTop ? 0 : `${45}`)}px;
  }

  + div.noPadding {
    padding-top: ${({ pt, disablePaddingTop }) => (disablePaddingTop ? 0 : `${pt}`)}px;
    padding-bottom: 0 !important;
  }

  @media (min-width: ${breakpoints.tablet}) {
    + div {
      padding-top: ${({ disablePaddingTop }) => (disablePaddingTop ? 0 : `${75}`)}px;
    }

    + div.noPadding {
      padding-top: ${({ pt, disablePaddingTop }) => (disablePaddingTop ? 0 : `${pt}`)}px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    + div {
      padding-top: ${({ disablePaddingTop }) => (disablePaddingTop ? 0 : `${65}`)}px;
    }

    + div.noPadding {
      padding-top: ${({ pt, disablePaddingTop }) => (disablePaddingTop ? 0 : `${pt}`)}px;
    }
  }
`;

export const MainContainerLite = styled.div<{ isLite?: boolean }>`
  padding-left: ${({ isLite }) => (isLite ? '32px' : '16px')};
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  height: 46px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 40px;
    padding-top: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 72px;
    padding-left: 80px;
  }
`;

export const WrapperHeaderLite = styled.div`
  div {
    position: absolute;
    // z-index: 1;
  }
`;

export const LogoWrapperLite = styled.div<{ isMobile?: boolean; isTablet?: boolean }>`
  svg {
    width: ${({ isMobile }) => {
      if (isMobile) {
        return '32px';
      }
      return '48px';
    }};
    height: auto;
  }
`;

// MVA on boarding
export const MainContainer = styled.header`
  display: flex;
  justify-content: space-between;
  height: ${pxToRem(62)};
  padding-left: ${pxToRem(16)};
`;

export const LogoWrapper = styled.div<{ small?: boolean }>`
  svg {
    margin: ${pxToRem(16, 'auto')};
    width: ${pxToRem(30)};
    height: auto;
  }
`;
