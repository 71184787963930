import React, { SVGProps, memo } from 'react';

const Search = (props: SVGProps<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
    <path
      className="stroke"
      stroke="#0d0d0d"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m17.265 17.265 9.402 9.402m-6.667-16a9.333 9.333 0 1 1-18.667 0 9.333 9.333 0 0 1 18.667 0Z"
    />
  </svg>
);

export default memo<SVGProps<SVGAElement>>(Search);
