import React from 'react';
import { Close, Logo } from '@vfit/shared-icons';
import { fonts, pxToFontSize, pxToLineHeight, pxToRem } from '@vfit/shared/themes';
import { AccordionNavigationType, IAccordionNavigationConfig } from '@vfit/shared/models';
import { AccordionNavigation } from '@vfit/shared/components';
import { handlerClickMenu } from '../../../header.utils';
import Footer from '../Footer/footer';
import {
  AccordionContainer,
  CloseBtn,
  LogoOverlay,
  OverlayContainer,
  Top,
} from './overlayMenu.style';
import { IOverlayMenu } from './overlayMenu.model';

const accordionNavigationConfig: IAccordionNavigationConfig = {
  fontFamily: fonts.light,
  fontSize: pxToFontSize(20),
  fontWeight: 400,
  lineHeight: pxToLineHeight(20, 28).toString(),
  iconSize: 24,
  fontFamilyLinks: fonts.regular,
  fontSizeLinks: pxToFontSize(14),
  fontWeightLinks: 400,
  lineHeightLiks: pxToLineHeight(14, 18).toString(),
  marginLeftLink: pxToRem(48),
  highlightColor: '#e60000',
  enableHighlight: true,
  fontFamilySecondLevel: fonts.regular,
  fontSizeSecondLevel: pxToFontSize(16),
  fontWeightSecondLevel: 400,
  lineHeightSecondLevel: pxToLineHeight(16, 20).toString(),
  iconSizeSecondLevel: 20,
  marginLeftSecondLevel: pxToRem(4),
};

const OverlayMenu: React.FC<IOverlayMenu> = ({
  header: { head, body },
  onTrack,
  setIsMenuOpen,
  isMenuOpen,
}) => (
  <OverlayContainer className={isMenuOpen}>
    <div>
      <Top>
        <LogoOverlay>
          <a href="https://www.google.it">
            <Logo />
          </a>
        </LogoOverlay>
        <CloseBtn aria-label="close" onClick={() => setIsMenuOpen('fade-out')}>
          <Close />
        </CloseBtn>
      </Top>
      <AccordionContainer>
        {body?.menuType && (
          <AccordionNavigation
            onTrack={onTrack}
            handlerClickMenu={handlerClickMenu}
            links={body?.menuType}
            config={accordionNavigationConfig}
            accordionNavigationType={AccordionNavigationType.MENU}
          />
        )}
      </AccordionContainer>
    </div>
    <Footer
      customerType={head?.customerType}
      otherInfo={head?.otherInfo}
      userInfo={head?.userInfo}
      onTrack={onTrack}
    />
  </OverlayContainer>
);

export default OverlayMenu;
