import styled, { createGlobalStyle } from 'styled-components';
import { colors, fonts, pxToCssFontSize } from '@vfit/shared/themes';

export const MainContainer = styled.header<{ pt?: number }>`
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  border-bottom: 1px solid ${colors.$f2f2f2};

  nav {
    width: 100%;
    background-color: ${colors.$ffffff};
  }

  + div {
    padding-top: ${({ pt }) => `${pt}`}px;
  }
`;

export const OverlayGlobalStyle = createGlobalStyle`
  body {
  overflow: hidden;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  padding-top: 24px;
`;

export const FooterLinksCol = styled.div`
  flex-grow: 1;
  width: 25%;

  p {
    margin: 0;
    margin-bottom: 18px;
    font-family: ${fonts.regular};
    color: white;
    ${pxToCssFontSize(20)}
    font-weight: 700;
  }

  div {
    display: flex;
    flex-direction: column;

    a {
      color: white;
      ${pxToCssFontSize(18)}
      font-weight: 400;
      font-family: ${fonts.regular};
      text-decoration: none;
      margin-bottom: 5px;
    }
  }
`;
