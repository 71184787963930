import { breakpoints, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const MainContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${pxToRem(72)};
  border-bottom: 1px solid #cccccc80;
`;

export const LogoWrapper = styled.div<{ small?: boolean }>`
  height: 63px;
  display: flex;
  align-items: center;
  padding-left: 80px;
  @media (max-width: ${breakpoints.desktop}) {
    height: 56px;
    padding-left: 16px;
  }
`;
