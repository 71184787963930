import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const OverlayContainer = styled.div`
  overflow-y: auto;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 50;
  width: 100vw;
  height: 100%;
  background-color: ${colors.$ffffff};
  top: 0;
  left: 0;
  opacity: 0;
  transform: translate3d(-100%, 0, 0);

  &.fade-in {
    @keyframes fade-in-left {
      from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }

    animation-name: fade-in-left;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &.fade-out {
    @keyframes fade-out {
      from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
      }
    }

    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }
`;

export const Top = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LogoOverlay = styled.div`
  margin: 8px 16px;
  width: 48px;
  height: 48px;

  svg {
    width: 48px;
  }

  a {
    display: flex;
    height: 100%;
    width: 100%;
  }
`;

export const CloseBtn = styled.button`
  width: 64px;
  height: 64px;
  background-color: ${colors.$e60000};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 21px;

  svg {
    width: 21px;
    fill: ${colors.$ffffff};
  }
`;

export const AccordionContainer = styled.div`
  margin-top: 16px;

  .accordionNavgationInnerContainer,
  .defaultLinkWrapper {
    padding: 0 20px;
  }
`;

