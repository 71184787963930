import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToFontSize } from '@vfit/shared/themes';
import { AccordionNavigationType } from '@vfit/shared/models';

interface IAccordionLinksContent {
  colorLinks?: string;
  fontFamilyLinks?: string;
  fontSizeLinks?: string;
  fontWeightLinks?: number;
  enableMargin?: boolean;
  lineHeight?: string;
  leftMargin?: string;
}

interface IAccordionLinksDefaultContent {
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number;
  background?: string;
  enableMargin?: boolean;
  lineHeight?: string;
  leftMargin?: string;
}

export const AccordionNavigationContainer = styled.div<{
  enableMargin?: boolean;
  marginLeft?: string;
}>`
  margin-left: ${({ enableMargin, marginLeft }) => (enableMargin ? marginLeft || '24px' : 0)};
`;

export const AccordionNavigationInnerContainer = styled.div<{
  accordionNavigationType?: AccordionNavigationType;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid
    ${({ accordionNavigationType }) =>
      accordionNavigationType === AccordionNavigationType.MENU ? '#F2F2F2' : '#BEBEBE'};

  @media (min-width: ${breakpoints.tablet}) {
    ${({ accordionNavigationType }) =>
      accordionNavigationType === AccordionNavigationType.FOOTER &&
      `:last-child {
      border-bottom: none;
    }`};
  }
`;

export const DefaultLinkWrapper = styled(AccordionNavigationInnerContainer)`
  height: 69px;
`;

export const AccordionLinksContent = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const AccordionNavigationLink = styled.a<IAccordionLinksDefaultContent>`
  margin-left: ${({ enableMargin, leftMargin }) => (enableMargin ? leftMargin || '24px' : 0)};
  font-family: ${({ fontFamily }) => fontFamily || fonts.regular};
  ${({ fontSize }) => fontSize || pxToFontSize(16)};
  font-weight: ${({ fontWeight }) => fontWeight || 400};
  cursor: pointer;
  color: ${({ color }) => color || colors.$262626};
  text-decoration: none;
  padding: 1rem 0;
  background: ${({ background }) => background || colors.$ffffff};
  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
`;

export const AccordionNavigationDefaultLink = styled.a<IAccordionLinksContent>`
  margin-left: ${({ enableMargin, leftMargin }) => (enableMargin ? leftMargin || '24px' : 0)};
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: ${({ fontSizeLinks }) => fontSizeLinks || pxToFontSize(16)};
  font-weight: ${({ fontWeightLinks }) => fontWeightLinks || 400};
  color: ${({ colorLinks }) => colorLinks || colors.$262626};
  font-family: ${({ fontFamilyLinks }) => fontFamilyLinks || fonts.regular};
  line-height: ${({ lineHeight }) => lineHeight || 'inherit'};
  text-decoration: none;
`;

