import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const WrapperContainer = styled.header<{ pt?: number; disablePaddingTop: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;

  nav {
    background-color: ${colors.$ffffff};
    //for sticky banner
    position: relative;
    z-index: 2;
  }

  + div {
    padding-top: ${({ pt, disablePaddingTop }) => (disablePaddingTop || !pt ? 0 : `${pt + 40}`)}px;
  }

  + div.noPadding {
    padding-top: ${({ pt, disablePaddingTop }) => (disablePaddingTop || !pt ? 0 : `${pt}`)}px;
    padding-bottom: 0 !important;
  }
`;

export const MenuUnderlay = styled.div`
  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  display: block;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 80%);
  z-index: -1;
  animation-name: fade-in;
  animation-duration: 0.3s;
`;
