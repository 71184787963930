import styled from 'styled-components';
import { colors, fonts, pxToCssFont, pxToCssFontSize, breakpoints } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  padding-left: 80px;
  display: flex;
  justify-content: space-between;
  height: 72px;
`;

export const LogoWrapper = styled.div<{ small?: boolean }>`
  svg {
    margin: 12px auto;
    width: ${({ small }) => (small ? '28px' : '48px')};
    height: auto;
  }
`;

export const Menu = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  margin-left: 68px;
  gap: 32px;
  margin-right: auto;
`;

export const MenuList = styled.li`
  display: flex;
  align-items: stretch;
  list-style: none;

  a {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    transition: 0.3s all ease;
  }

  a:link {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 400;
    ${pxToCssFont(20, 28)}
  }

  a.showLine {
    border-bottom: 2px solid ${colors.$e60000};
    color: ${colors.$e60000};
  }
`;

export const ActionsMenu = styled.ul<{ isEnabledMultiLang?: boolean }>`
  display: flex;
  padding: 0;
  margin: 0;
  margin-right: ${({ isEnabledMultiLang }) => (isEnabledMultiLang ? '49px' : '119px')};
  align-items: center;
`;

export const ActionsMenuList = styled.li`
  list-style: none;
  margin-left: 40px;
  width: 20px;
  height: auto;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 20px;
  }
  
  a:hover svg path.stroke {
    stroke: ${colors.$e60000};
  }

  a:hover svg path.fill {
    fill: ${colors.$e60000};
  }

  .search-container {
    position: relative;
    display: inline-block;
    margin: 4px 2px;
    height: 28px;
    width: 26px;
    vertical-align: bottom;
  }

  .searchbutton {
    position: absolute;
    cursor: pointer;
    ${pxToCssFontSize(22)}
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .search:focus + .searchbutton {
    transition-duration: 0.4s;
    background-color: white;
    color: black;
  }

  .search {
    position: absolute;
    left: 49px; /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
    background-color: white;
    outline: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    border: 1px solid black;
  }

  .search:focus {
    width: 363px; /* Bar width+1px */
    padding: 0 16px 0 0;
  }

  .expandright {
    left: auto;
    right: 200px;
  }

  .expandright:focus {
    padding: 0 0 0 16px;
  }
`;
