import React, { Children, useCallback } from 'react';
import { ConfigMenu, INavigationMenu } from '@vfit/shared/models';
import { handlerClickMenu } from '../../header.utils';
import { MainContainer, Menu, DropDownContainer, DropDownContent } from './footer.style';
import { IFooterProps } from './footer.model';

const Footer: React.FC<IFooterProps> = ({
  menuType,
  isFirstLevelMenuOpen,
  isSecondLevelMenuOpen,
  openSecondLevelMenu,
}) => {
  const toggleDropDown = useCallback(
    (index: number) => isSecondLevelMenuOpen.isHovered && index === isSecondLevelMenuOpen.menuIndex,
    [isSecondLevelMenuOpen]
  );

  return (
    <MainContainer>
      {isFirstLevelMenuOpen.isHovered && (
        <Menu
          onMouseLeave={() => {
            openSecondLevelMenu((prevData: ConfigMenu) => ({
              ...prevData,
              isHovered: false,
            }));
          }}
        >
          {Children.toArray(
            menuType[isFirstLevelMenuOpen.menuIndex]?.links?.map(
              (item: INavigationMenu, index: number) => (
                <li>
                  <a
                    onClick={(evt) => handlerClickMenu(evt, item)}
                    className={toggleDropDown(index) ? 'hovered' : ''}
                    onMouseEnter={() => {
                      openSecondLevelMenu({
                        menuIndex: index,
                        isHovered: true,
                      });
                    }}
                    href={item.url}
                  >
                    {item.category}
                  </a>
                  {toggleDropDown(index) && (
                    <DropDownContainer>
                      {item?.links?.map((elm) => (
                        <DropDownContent key={elm.id}>
                          <a href={elm.url}>{elm.category}</a>
                        </DropDownContent>
                      ))}
                    </DropDownContainer>
                  )}
                </li>
              )
            )
          )}
        </Menu>
      )}
    </MainContainer>
  );
};

export default Footer;

