import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  background-color: #4a4d4e;
  height: 24px;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;

  @media (min-width: ${breakpoints.tablet}) {
    height: 29px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    justify-content: space-between;
    height: 34px;
  }
`;

export const CustomerTypeBox = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 213px;
  }
`;

export const OtherInfoBox = styled(CustomerTypeBox)`
  margin-left: 0;
  margin-right: 113px;

  a {
    padding: 0;
  }
`;

export const Box = styled.div`
  display: flex;
  margin-left: 17px;

  a {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.$bebebe};
  }
`;

export const ListCustomer = styled.li`
  list-style: none;
  display: flex;

  a {
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.$bebebe};

    @media (max-width: ${breakpoints.desktop}) {
      white-space: nowrap;
    }
  }

  a:hover,
  a:active {
    color: ${colors.$0d0d0d};
  }

  a:link {
    text-decoration: none;
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 400;
    ${pxToCssFont(14, 18)}
  }

  a:link:hover,
  .selected {
    background-color: ${colors.$ffffff};
    color: ${colors.$0d0d0d};
  }

  a:link:active {
    background-color: ${colors.$ffffff};
    color: ${colors.$0d0d0d};
  }

  &:active {
    background-color: ${colors.$ffffff};
  }

  &:hover {
    path {
      fill: ${colors.$0d0d0d};
    }
  }
`;

export const IconMarkerWrapper = styled.div`
  display: flex;
  margin-right: 11px;

  img {
    object-fit: contain;
    width: 10px;
    height: 12.7px;
  }
`;

export const ListOtherInfo = styled(ListCustomer)`
  hover {
    cursor: pointer;
    background-color: ${colors.$ffffff};

    /* stylelint-disable no-descending-specificity */
    a {
      color: ${colors.$0d0d0d};
    }

    img {
      filter: brightness(30%);
    }
  }
`;

