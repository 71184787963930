import React, { useLayoutEffect, useRef, useState } from 'react';
import { OverlayGlobalStyle } from './headerMobile.style';
import Head from './components/Head/head';
import OverlayMenu from './components/OverlayMenu/overlayMenu';
import { IHeaderProps } from '../header.models';
import TopHeaderBanner from '../TopHeaderBanner/topHeaderBanner';

const HeaderMobile: React.FC<IHeaderProps> = ({ header, topHeaderBanner, onTrack, onTranslate }) => {
  const [isMenuOpen, setIsMenuOpen] = useState('');
  const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useLayoutEffect(() => {
    if (containerRef && containerRef.current && header.getContainerHeight) {
      header.getContainerHeight(containerRef.current.clientHeight);
    }
  }, [header.getContainerHeight]);

  const getMobileTopBanner = () => {
    if (header?.topBanner?.isEnabledForAllPages === 'true') {
      return <TopHeaderBanner {...header?.topBanner} />;
    }
    if (topHeaderBanner?.isEnabled === 'true') {
      if (topHeaderBanner?.useHeaderConfig === 'true') {
        return <TopHeaderBanner {...header?.topBanner} />;
      }
      return <TopHeaderBanner {...topHeaderBanner} />;
    }
    return null;
  };

  return (
    <>
      {isMenuOpen === 'fade-in' && <OverlayGlobalStyle />}
      <nav ref={containerRef}>
        {getMobileTopBanner()}
        <Head
          actionsMenu={header?.body?.actionsMenu}
          openMenu={setIsMenuOpen}
          homeRedirect={header?.body?.headerLogo?.url}
          customerType={header.head?.customerType}
          onTranslate={onTranslate}
          disableBlackHead={header?.topBanner?.isEnabled}
        />
        <OverlayMenu
          onTrack={onTrack}
          header={header}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      </nav>
    </>
  );
};

export default HeaderMobile;
