import { QueryClient } from 'react-query';
import { getParamsInUrl } from '@vfit/consumer/data-access';
import { mergeJSONDiff } from '../prefetch/prefetch.utils';
import { checkWindow } from '@vfit/shared/data-access';

export const getHubKey = (pageKey: string, apiProductList: string): string => {
  const pageKeyFormatted = pageKey?.toLowerCase().split('-').join('').split('/').join('-');
  const pageKeySplitted = `${pageKeyFormatted?.split('-')?.[0]}`;
  const apiProductListFormatted = `${apiProductList?.toLowerCase().split('/').join('-')}`;
  if (!pageKey) return apiProductListFormatted;
  return `${pageKeySplitted}-${apiProductListFormatted}`;
};

/**
 * Method to translate query key in lang
 * @param queryClient
 * @param key
 * @param lang
 */
const translateKeyByLang = (queryClient: QueryClient, key: string, lang?: string) => {
  const queryDefault = queryClient.getQueryData(`${key}-default`) || {};
  if (!lang || lang === 'it') {
    queryClient.setQueryData(key, queryDefault);
  } else {
    const queryDefaultCloned = JSON.parse(JSON.stringify(queryDefault)) || {};
    const queryLang = queryClient.getQueryData(`${key}-${lang}`) || {};
    mergeJSONDiff(queryDefaultCloned as object, queryLang as object);
    queryClient.setQueryData(key, queryDefaultCloned || {});
  }
};

/**
 * Method to init the translate query key
 * @param queryClient
 * @param key
 */
const translateKeyByLangInverse = (queryClient: QueryClient, key: string) => {
  const queryDefault = queryClient.getQueryData(`${key}`) || {};
  queryClient.setQueryData(`${key}-default`, JSON.parse(JSON.stringify(queryDefault)));
};

/**
 * Method to translate products/devices query key in lang
 * @param queryClient
 * @param key
 * @param lang
 */
const translateDeviceAndProductsByLang = (queryClient: QueryClient, key: string, lang?: string) => {
  const queryDefault = queryClient.getQueryData(`${key}-default`) || {};
  if (!lang || lang === 'it') {
    queryClient.setQueryData(key, queryDefault);
  } else {
    const queryDefaultCloned = JSON.parse(JSON.stringify(queryDefault)) || {};
    const queryLang = queryClient.getQueryData(`${key}-${lang}`) || {};
    const newData = {};
    Object.keys(queryDefaultCloned)?.forEach((qK) => {
      const el = queryDefaultCloned?.[qK] || {};
      if (queryLang?.[qK]) {
        mergeJSONDiff(el as object, queryLang[qK] as object);
      }
      newData[qK] = el;
    });
    queryClient.setQueryData(key, newData);
  }
};

/**
 * Method to retrieve the local language
 * from local storage and query string
 */
const getLocalLanguage = (): string => {
  if (!checkWindow()) {
    return 'it';
  }
  const queryStringLang = getParamsInUrl('lang');
  const localLang = localStorage.getItem('lang');
  // const browserLang = checkWindow() ? window.navigator?.language?.split('-')?.[0] || '' : '';
  return queryStringLang || localLang || 'it';
};

export { translateKeyByLang, translateDeviceAndProductsByLang, getLocalLanguage, translateKeyByLangInverse };
