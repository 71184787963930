import dynamic from 'next/dynamic';

import { FlagsContainer } from './Flags.style';
const FlagsSvg = dynamic(() => import('@vfit/shared-icon-flag').then(e => e.FlagsSvg), { ssr: false });

const Flags = ({
  flag,
  circle = false,
  width,
  height,
}: {
  flag: string | undefined;
  circle?: boolean;
  width: number;
  height: number;
}) => {
  return (
    <FlagsContainer width={width} height={height} border={circle}>
      <FlagsSvg flag={flag || ''} />
    </FlagsContainer>
  );
};

export default Flags;

