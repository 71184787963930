import { checkWindow } from '@vfit/shared/data-access';
import { ITrackView } from '@vfit/shared/data-access';

export const trackLink = (
  linkName: string,
  event_category = 'users',
  event_action = 'click',
  event_name: string | string[] = 'ui interaction',
  tracking_type = 'link',
  transaction_coupon_code = '',
  product_discount_value = '',
  product_discount_percentage = '',
  cart_product_price = ''
) => {
  const pageName = (window as any)?.trackLink?.pageName;
  let event_label_track = linkName;
  if (pageName) {
    event_label_track = `${pageName} ${linkName || ''}`;
  }
  const payload = {
    event_name,
    event_label_track,
    event_category,
    event_action,
    tracking_type,
    ...(transaction_coupon_code && { transaction_coupon_code }),
    ...(product_discount_value && { product_discount_value }),
    ...(product_discount_percentage && { product_discount_percentage }),
    ...(cart_product_price && { cart_product_price }),
  };
  if (checkWindow() && (window as any)?.trackLink?.track)
    (window as any).trackLink?.track?.(payload);
};

export const trackView = (trackObject: ITrackView) => {
  if (checkWindow() && (window as any)?.trackView?.track)
    (window as any).trackView.track?.(trackObject);
};

export const setTrackLink = (trackViewMethod, pageName?: string) => {
  if (!checkWindow()) return;
  window['trackLink'] = {
    track: trackViewMethod,
    pageName,
  };
};

export const setTrackView = (trackViewMethod) => {
  if (!checkWindow()) return;
  window['trackView'] = {
    track: trackViewMethod,
  };
};
