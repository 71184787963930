declare global {
  interface Window {
    Optanon: any;
  }
}

export const EVENTS_TYPE = ['COOKIE_PREFERENCES'];

export const COOKIE_PREFERENCES_FALLBACK_URL =
  'https://www.vodafone.it/portal/Privati/Area-Privacy/La-nostra-informativa?icmp=cbu_web_FOOTER_preferenze-cookie';

export const ONCLICK_EVENTS = {
  COOKIE_PREFERENCES: () => {
    if (!window.Optanon) {
      window.open(COOKIE_PREFERENCES_FALLBACK_URL, '_blank');
      return;
    }

    try {
      const oneTrustConsentSdk = document.querySelector('#onetrust-consent-sdk');

      if (oneTrustConsentSdk) {
        (oneTrustConsentSdk as HTMLElement)?.style?.removeProperty('display');
        (oneTrustConsentSdk as HTMLElement)?.style?.removeProperty('z-index');
      }

      window.Optanon.ToggleInfoDisplay();
    } catch (e) {
      console.log(e);
    }
  },
};
