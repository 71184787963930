import type { SVGProps } from 'react';
import { memo } from 'react';

const SvgComponent = (): JSX.Element => {
  return (
    <svg width="162" height="32" viewBox="0 0 162 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.6587 17.9512L41.5611 7.51215H37.1709L43.805 23.2195H47.3172L53.7563 7.51215H49.6587L45.6587 17.9512Z" fill="#E60000"/>
        <path d="M67.3172 9.65859C66.7318 8.87811 65.9514 8.29274 64.9758 7.80493C64.0001 7.31713 62.927 7.12201 61.6587 7.12201C60.3904 7.12201 59.3172 7.31713 58.3416 7.80493C57.366 8.29274 56.5855 8.87811 56.0001 9.65859C55.4148 10.4391 54.927 11.3171 54.6343 12.2927C54.3416 13.2683 54.1465 14.244 54.1465 15.3171C54.1465 16.2927 54.3416 17.3659 54.6343 18.3415C54.927 19.3171 55.4148 20.1952 56.0001 20.9757C56.5855 21.7561 57.366 22.3415 58.3416 22.8293C59.3172 23.3171 60.3904 23.5122 61.6587 23.5122C62.927 23.5122 64.0001 23.3171 64.9758 22.8293C65.9514 22.3415 66.7318 21.7561 67.3172 20.9757C67.9026 20.1952 68.3904 19.3171 68.6831 18.3415C68.9758 17.3659 69.1709 16.3903 69.1709 15.3171C69.1709 14.3415 68.9758 13.2683 68.6831 12.2927C68.3904 11.3171 67.9026 10.4391 67.3172 9.65859ZM64.9758 17.1708C64.7806 17.7562 64.5855 18.244 64.2928 18.7318C64.0001 19.122 63.6099 19.5122 63.2197 19.8049C62.8294 20.0976 62.244 20.1952 61.6587 20.1952C61.0733 20.1952 60.5855 20.0976 60.0977 19.8049C59.6099 19.5122 59.3172 19.2196 59.0245 18.7318C58.7319 18.3415 58.5367 17.7562 58.3416 17.1708C58.1465 16.5854 58.1465 16.0001 58.1465 15.3171C58.1465 14.6342 58.244 14.0488 58.3416 13.4635C58.5367 12.8781 58.7319 12.3903 59.0245 11.9025C59.3172 11.5123 59.7075 11.122 60.0977 10.9269C60.4879 10.6342 61.0733 10.5366 61.6587 10.5366C62.244 10.5366 62.7319 10.6342 63.2197 10.9269C63.6099 11.2196 64.0001 11.5123 64.2928 11.9025C64.5855 12.2927 64.7806 12.8781 64.9758 13.4635C65.1709 14.0488 65.1709 14.6342 65.1709 15.3171C65.1709 16.0001 65.0733 16.5854 64.9758 17.1708Z" fill="#E60000"/>
        <path d="M81.3661 7.60975C80.3905 7.31707 79.5124 7.12195 78.7319 7.12195C77.4636 7.12195 76.3905 7.31707 75.4149 7.80487C74.4392 8.29268 73.6588 8.87804 73.0734 9.65853C72.488 10.439 72.0002 11.3171 71.7075 12.2927C71.4148 13.2683 71.2197 14.2439 71.2197 15.3171C71.2197 16.3902 71.3173 17.4634 71.61 18.439C71.9027 19.4146 72.2929 20.2927 72.8783 21.0732C73.4636 21.8536 74.1466 22.439 74.927 22.9268C75.7075 23.4146 76.6831 23.6097 77.7563 23.6097C78.5368 23.6097 79.3173 23.4146 79.9027 23.1219C80.488 22.8293 80.9758 22.439 81.3661 22.0488L81.5612 23.2195H85.2685V1.46341H81.2685V7.60975H81.3661ZM81.3661 18.7317C81.171 19.1219 80.7807 19.4146 80.2929 19.7073C79.8051 20 79.2197 20.0976 78.7319 20.0976C78.1466 20.0976 77.5612 20 77.1709 19.7073C76.6831 19.4146 76.3905 19.1219 76.0978 18.6341C75.8051 18.1463 75.61 17.6585 75.4149 17.0732C75.3173 16.4878 75.2197 15.8049 75.2197 15.2195C75.2197 14.6341 75.3173 14.0488 75.4149 13.4634C75.61 12.878 75.8051 12.3902 76.0978 12C76.3905 11.5122 76.8783 11.2195 77.3661 10.9268C77.8539 10.6341 78.4392 10.5366 79.1222 10.5366C79.8051 10.5366 80.488 10.7317 81.2685 11.0244V18.7317H81.3661Z" fill="#E60000"/>
        <path d="M99.1218 8.58542C98.6339 8.09762 97.951 7.70737 97.2681 7.51225C96.5852 7.31713 95.7071 7.12201 94.9266 7.12201C94.1462 7.12201 93.4632 7.21957 92.8779 7.31713C92.1949 7.41469 91.7071 7.60981 91.1218 7.80493C90.634 8.00006 90.1461 8.19518 89.8535 8.48786C89.4632 8.68298 89.2681 8.87811 89.073 9.07323L90.4388 11.9025C90.634 11.8049 90.8291 11.6098 91.0242 11.4147C91.3169 11.2196 91.6096 11.122 91.9022 10.9269C92.1949 10.7318 92.5852 10.6342 92.9754 10.5366C93.3657 10.4391 93.8535 10.3415 94.2437 10.3415C95.0242 10.3415 95.6096 10.5366 96.0974 10.9269C96.5852 11.3171 96.7803 11.9025 96.7803 12.7805V12.9757C96.39 12.9757 95.8047 13.0732 95.2193 13.1708C94.634 13.2683 94.0486 13.2683 93.4632 13.4635C92.6827 13.561 91.9998 13.7562 91.3169 14.0488C90.634 14.3415 90.0486 14.6342 89.5608 15.0244C89.073 15.4147 88.5852 15.9025 88.2925 16.5854C87.9998 17.1708 87.8047 17.8537 87.8047 18.6342C87.8047 19.4147 87.9998 20.0976 88.2925 20.7805C88.5852 21.3659 88.9754 21.9513 89.4632 22.3415C89.951 22.7318 90.5364 23.122 91.1218 23.3171C91.7071 23.5122 92.3901 23.7074 93.073 23.7074C93.8535 23.7074 94.6339 23.6098 95.3169 23.3171C95.9998 23.0244 96.5852 22.6342 96.9754 22.1464L97.1705 23.3171H100.878V13.1708C100.878 12.0976 100.683 11.2196 100.39 10.4391C99.9998 9.65859 99.6096 9.07323 99.1218 8.58542ZM96.7803 19.0244C96.39 19.3171 95.9998 19.6098 95.512 19.8049C95.0242 20.0001 94.5364 20.0976 93.951 20.0976C93.2681 20.0976 92.6827 19.9025 92.2925 19.6098C91.9022 19.3171 91.7071 18.8293 91.7071 18.3415C91.7071 17.8537 91.9022 17.3659 92.1949 16.9757C92.4876 16.5854 93.1705 16.2927 94.0486 16.0976C94.4388 16.0001 94.9266 15.9025 95.3169 15.9025C95.8047 15.8049 96.1949 15.8049 96.6827 15.8049V19.0244H96.7803Z" fill="#E60000"/>
        <path d="M109.561 5.17071C109.951 4.87803 110.439 4.68291 111.024 4.68291C111.61 4.68291 112.195 4.78047 112.585 4.87803C112.976 4.97559 113.268 5.07315 113.366 5.17071L113.854 1.85364C113.756 1.75608 113.366 1.65852 112.683 1.4634C112 1.26828 111.22 1.17072 110.244 1.17072C109.463 1.17072 108.781 1.26828 108.195 1.4634C107.61 1.65852 107.024 1.9512 106.537 2.43901C106.049 2.92681 105.659 3.41462 105.463 4.19511C105.171 4.87803 105.073 5.75608 105.073 6.82925V7.7073H103.024V10.9268H105.073V23.4146H109.073V10.8292H112.39V7.60974H108.976V6.82925C108.976 6.04876 109.171 5.4634 109.561 5.17071Z" fill="#E60000"/>
        <path d="M126.342 9.65859C125.756 8.87811 124.976 8.29274 124 7.80493C123.025 7.31713 121.951 7.12201 120.683 7.12201C119.415 7.12201 118.342 7.31713 117.366 7.80493C116.39 8.29274 115.61 8.87811 115.025 9.65859C114.439 10.4391 113.951 11.3171 113.659 12.2927C113.366 13.2683 113.171 14.244 113.171 15.3171C113.171 16.2927 113.366 17.3659 113.659 18.3415C113.951 19.3171 114.439 20.1952 115.025 20.9757C115.61 21.7561 116.39 22.3415 117.366 22.8293C118.342 23.3171 119.415 23.5122 120.683 23.5122C121.951 23.5122 123.025 23.3171 124 22.8293C124.976 22.3415 125.756 21.7561 126.342 20.9757C126.927 20.1952 127.415 19.3171 127.707 18.3415C128 17.3659 128.195 16.3903 128.195 15.3171C128.195 14.3415 128 13.2683 127.707 12.2927C127.512 11.3171 127.025 10.4391 126.342 9.65859ZM124.098 17.1708C123.903 17.7562 123.707 18.244 123.415 18.7318C123.122 19.122 122.732 19.5122 122.342 19.8049C121.951 20.0976 121.366 20.1952 120.781 20.1952C120.195 20.1952 119.707 20.0976 119.22 19.8049C118.732 19.5122 118.439 19.2196 118.147 18.7318C117.854 18.3415 117.659 17.7562 117.464 17.1708C117.268 16.5854 117.268 16.0001 117.268 15.3171C117.268 14.6342 117.366 14.0488 117.464 13.4635C117.659 12.8781 117.854 12.3903 118.147 11.9025C118.439 11.5123 118.829 11.122 119.22 10.9269C119.61 10.6342 120.195 10.5366 120.781 10.5366C121.366 10.5366 121.854 10.6342 122.342 10.9269C122.732 11.2196 123.122 11.5123 123.415 11.9025C123.707 12.2927 123.903 12.8781 124.098 13.4635C124.293 14.0488 124.293 14.6342 124.293 15.3171C124.293 16.0001 124.195 16.5854 124.098 17.1708Z" fill="#E60000"/>
        <path d="M143.317 8.68298C142.829 8.09762 142.244 7.70737 141.561 7.51225C140.878 7.21957 140.097 7.12201 139.317 7.12201C138.536 7.12201 137.756 7.21957 136.878 7.51225C136.097 7.80494 135.414 8.19518 134.732 8.68298L134.536 7.41469H130.829V23.122H134.829V12.0976C135.219 11.7074 135.707 11.3171 136.195 11.0244C136.683 10.7318 137.268 10.5366 137.951 10.5366C138.829 10.5366 139.512 10.8293 140 11.5123C140.488 12.1952 140.78 13.0732 140.78 14.244V23.2196H144.78V13.9513C144.78 12.7805 144.683 11.7074 144.39 10.8293C144.195 9.95128 143.805 9.26835 143.317 8.68298Z" fill="#E60000"/>
        <path d="M161.561 15.7074C161.561 14.244 161.366 12.8781 160.976 11.8049C160.586 10.7318 160.098 9.85372 159.512 9.17079C158.927 8.48786 158.147 8.00006 157.268 7.60981C156.39 7.31713 155.512 7.12201 154.537 7.12201C153.561 7.12201 152.586 7.31713 151.708 7.70738C150.829 8.09762 150.049 8.58542 149.366 9.26835C148.683 9.95128 148.195 10.8293 147.805 11.8049C147.415 12.7805 147.22 14.0488 147.22 15.3171C147.22 16.683 147.415 17.9513 147.805 18.9269C148.195 19.9025 148.781 20.7805 149.464 21.4635C150.147 22.1464 150.927 22.6342 151.903 23.0244C152.781 23.3171 153.756 23.5122 154.829 23.5122C155.61 23.5122 156.39 23.4147 157.073 23.3171C157.756 23.2196 158.342 23.0244 158.829 22.9269C159.317 22.7318 159.708 22.6342 160.098 22.4391C160.39 22.244 160.683 22.1464 160.878 21.9513L159.805 18.6342C159.708 18.7318 159.512 18.9269 159.22 19.0244C158.927 19.2196 158.634 19.3171 158.244 19.5122C157.854 19.7074 157.464 19.8049 156.976 19.9025C156.488 20.0001 156 20.0976 155.415 20.0976C154.244 20.0976 153.269 19.8049 152.488 19.2196C151.708 18.6342 151.317 17.6586 151.22 16.3903H161.464V15.7074H161.561ZM151.512 13.4635C151.61 12.3903 152 11.6098 152.586 11.122C153.269 10.6342 153.854 10.3415 154.634 10.3415C154.927 10.3415 155.317 10.4391 155.61 10.5366C155.903 10.6342 156.195 10.8293 156.488 11.122C156.781 11.4147 156.976 11.7074 157.171 12.0976C157.366 12.4879 157.464 12.9757 157.561 13.561H151.512V13.4635Z" fill="#E60000"/>
        <path d="M22.8294 1.46341C22.5367 1.36585 22.1465 1.36584 21.6587 1.36584C18.3416 1.36584 14.6343 2.82926 12.0001 4.9756C9.26841 7.2195 7.12207 11.1219 7.12207 15.0244C7.12207 21.0732 11.7074 24.7805 16.0977 24.7805C21.5611 24.7805 24.683 20.2927 24.683 16.2927C24.683 12.1951 22.4391 9.26828 17.5611 8.09755C17.5611 7.99999 17.5611 7.90243 17.5611 7.80487C17.5611 4.78048 19.9026 2.14633 22.8294 1.46341Z" fill="white"/>
        <path d="M30.2439 8.87805C28.6829 5.65854 26.0488 3.12195 22.7317 1.56098C19.8049 2.14634 17.561 4.87805 17.561 7.90244C17.561 8 17.561 8.09756 17.561 8.19512C22.439 9.36585 24.6829 12.2927 24.6829 16.3902C24.6829 20.4878 21.4634 24.878 16.0976 24.878C11.7073 24.878 7.12195 21.1707 7.12195 15.122C7.12195 11.122 9.26829 7.31707 12 5.07317C14.6341 2.82927 18.3415 1.46341 21.6585 1.46341C22.0488 1.46341 22.5366 1.46341 22.8293 1.56098C20.7805 0.585365 18.439 0 16 0C12.2927 0 8.87805 1.26829 6.14634 3.41463C2.43902 6.34146 0 10.8293 0 16C0 24.7805 7.12195 32 16 32C24.878 32 32 24.8781 32 16C31.9024 13.3659 31.3171 11.0244 30.2439 8.87805Z" fill="#E60000"/>
    </svg>
  );
};
export default memo<SVGProps<SVGSVGElement>>(SvgComponent);

