import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomerTypeContainer = styled.div`
  margin-left: 23px;
  margin-bottom: 30px;
  margin-top: 10px;
  width: 85%;
`;

export const ActionsLink = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin: 0;
  background-color: transparent;
  border: none;
  color: #0096ae;

  p {
    padding: 0;
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(14, 18)}
  }

  &:active {
    background-color: #f0f0f0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const WrapperSvg = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 4px;

  svg path {
    stroke: #0096ae;
    stroke-width: 1px;
  }
`;

export const OtherActionsContainer = styled.div`
  display: flex;
  height: 66px;
  border-top: 1px solid ${colors.$f2f2f2};
  border-bottom: 1px solid transparent;
`;

export const LeftButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  border-right: 1px solid ${colors.$f2f2f2};

  p {
    margin: 0;
    margin-left: 8px;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(14, 18)}
    color: #666;
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: #f0f0f0;
  }
`;

export const IconButtonsWrapper = styled.div`
  width: 16px;
  height: 16px;

  svg path {
    fill: #666;
  }

  &.align {
    padding-top: 2px;

    svg path {
      fill: none;
      stroke: #666;
    }
  }
`;

export const RightButton = styled(LeftButton)`
  border: none;
`;

export const IconMarkerWrapper = styled.div`
  display: flex;
  margin-right: 11px;

  img {
    object-fit: contain;
    max-width: 16px;
    max-height: 12.7px;
  }
`;

