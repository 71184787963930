import React, { SVGProps, memo } from 'react';

const HamburgerMenu = (props: SVGProps<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 15">
    <path
      fill="#0d0d0d"
      d="M19.5 14.04a.5.5 0 0 0 0-1v1Zm-19-1a.5.5 0 1 0 0 1v-1Zm19-5.54a.5.5 0 0 0 0-1v1Zm-19-1a.5.5 0 0 0 0 1v-1Zm19-5.469a.5.5 0 0 0 0-1v1Zm-19-1a.5.5 0 0 0 0 1v-1Zm19 13.008H.5v1h19v-1Zm0-6.539H.5v1h19v-1Zm0-6.469H.5v1h19v-1Z"
    />
  </svg>
);

export default memo<SVGProps<SVGAElement>>(HamburgerMenu);
