import React, { SVGProps, memo } from 'react';

const Close = (props: SVGProps<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.25"
      d="M1.167 22.833 22.833 1.167m0 21.666L1.167 1.167"
    />
  </svg>
);

export default memo<SVGProps<SVGAElement>>(Close);
