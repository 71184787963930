import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const SearchBoxContainer = styled.div`
  width: 0;
  background-color: ${colors.$bebebe};

  .yxt-SearchBar-container {
    border: none;
    box-shadow: none;
    margin-top: 3px;
    margin-left: 10px;

    form {
      :focus {
        border: inherit;
      }

      input {
        font-family: ${fonts.light};
        padding-right: 44px;
        text-overflow: ellipsis;
      }
    }
  }

  .yxt-AutoComplete {
    ::before {
      background-color: white;
    }

    ul li {
      font-family: ${fonts.light};
    }
  }

  .yxt-SearchBar-clear {
    display: none;
  }
`;

export const CloseContainer = styled.div`
  svg {
    width: 17px !important;
    margin-right: 5px !important;
  }
`;

export const SearchBoxContent = styled.div<{ isOpen: boolean }>`
  position: relative;
  min-width: 31px;
  width: ${({ isOpen }) => (isOpen ? '200px' : 0)};
  height: 50px;
  border-radius: 10px;
  float: right;
  border: ${({ isOpen }) => (isOpen ? '0.805957px solid white' : 'none')};
  box-shadow: ${({ isOpen }) => (isOpen ? '0 0 0.625rem 0 rgb(0 0 0 / 18%)' : 'none')};
  transition: width 0.2s;

  svg {
    path {
      stroke: ${colors.$0d0d0d};
    }
  }

  :hover {
    svg {
      path {
        stroke: ${colors.$e60000};
      }
    }
  }

  .searchbox-icon {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    ${pxToCssFont(22, 50)}
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    text-align: center;
    cursor: pointer;
    color: ${colors.$ffffff};
    z-index: 2;

    svg {
      width: 24px;

      @media (min-width: ${breakpoints.tablet}) {
        width: 25px;
      }
    }
  }

  button {
    background: transparent;
    border: none;
  }

  button:hover svg {
    path {
      stroke: ${colors.$e60000};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: ${({ isOpen }) => (isOpen ? '280px' : 0)};
  }
`;

