import { Close, Search } from '@vfit/shared-icons';
import React, { useEffect, useState } from 'react';
import { LoggerInstance, checkWindow } from '@vfit/shared/data-access';
import { ISearchBox } from '@vfit/shared/models';
import { CloseContainer, SearchBoxContainer, SearchBoxContent } from './SearchBox.style';

declare global {
  interface Window {
    initAnswers?: () => void
  }
  const ANSWERS: {
    components?:{
      removeByName: (arg0: string) => void
    };
  }
}

// TODO: Refactoring searchbox: Define overlopperItemId
const SearchBox: React.FC<ISearchBox> = ({ overlopperItemId, setIsOpenSearch }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchBarRead, setSearchBarRead] = useState(false);

  useEffect(() => {
    setIsOpenSearch?.(isOpen);
  }, [isOpen]);

  const BREAKPOINT_WIDTH = 1024;
  const BREAKPOINT_TABLET = 766.88;
  const DEFAULT_WIDTH = 200;
  const MAX_DEFAULT_WIDTH = 280;
  const MIN_DEFAULT_WIDTH = 0;
  const CLOSE_ITEM_DEFAULT_WIDTH = 50;
  const CHANGABLED_ITEM_ID = 'search-box';

  const onSearhBarEvent = (event) => {
    const { detail } = event as CustomEvent as { detail: {isReady:boolean} };
    if (detail.isReady){
      setSearchBarRead(true)
    }
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('search-bar-ready', onSearhBarEvent);
    }
    return () => document.removeEventListener('search-bar-ready', onSearhBarEvent);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const input = document.getElementById('yxt-SearchBar-input--search-bar');
      if (input) input.focus();
    }
  }, [isSearchBarRead,isOpen]);

  useEffect(() => {
    // if (checkWindow() && window.initAnswers) {
    //   setTimeout(() => {
    //     checkWindow() && window?.initAnswers?.();
    //     setTimeout(() => {
    //       const submit = document.getElementsByClassName('yxt-SearchBar-button');
    //       if (submit) submit?.[0]?.parentNode?.removeChild(submit[0]);
    //     }, 500);
    //   }, 500);
    // }
    return () => {
      try {
        if (ANSWERS && ANSWERS?.components) ANSWERS?.components?.removeByName('search-bar');
      } catch (e) {
        LoggerInstance.debug('ANSWER is not defined');
      }
    };
  }, []);

  const changeWidth = () => {
    const element = document.getElementById(CHANGABLED_ITEM_ID);
    if (element && window.innerWidth < BREAKPOINT_WIDTH) {
      if (isOpen) {
        if (window.innerWidth < BREAKPOINT_TABLET) {
          element.style.width = `${DEFAULT_WIDTH}px`;
        } else {
          element.style.width = `${MAX_DEFAULT_WIDTH}px`;
        }
      } else {
        element.style.width = `${MIN_DEFAULT_WIDTH}px`;
      }
    } else if (!isOpen && element) {
      element.style.width = `${CLOSE_ITEM_DEFAULT_WIDTH}px`;
    } else if (overlopperItemId) {
      const overlapedItem = document.getElementById(overlopperItemId)?.getBoundingClientRect();
      const changabledItem = document.getElementById(CHANGABLED_ITEM_ID)?.getBoundingClientRect();
      if (overlapedItem && changabledItem && element) {
        const res =
          Number(changabledItem?.x) + Number(changabledItem?.width) - Number(overlapedItem?.x);
        const width = Math.abs(Number(overlapedItem?.width) - Number(res));
        element.style.width =
          res > MIN_DEFAULT_WIDTH && width < MAX_DEFAULT_WIDTH
            ? `${width}px`
            : `${MAX_DEFAULT_WIDTH}px`;
      }
    }
  };

  const onOpenClose = () => {
    const elementInput = document.getElementById('searchbox-input');
    if (!isOpen && elementInput) {
      elementInput.focus();
    }
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    changeWidth();
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', changeWidth);
    return () => window.removeEventListener('resize', changeWidth);
  });

  return (
    <SearchBoxContainer>
      <SearchBoxContent id={CHANGABLED_ITEM_ID} isOpen={isOpen}>
        <div
          className="search-form"
          style={{
            width: '100%',
            display: isOpen ? 'block' : 'none',
          }}
        />
        <button disabled={!isSearchBarRead} type="button" className="searchbox-icon" onClick={onOpenClose} aria-label='ricerca'>
          {isOpen ? (
            <CloseContainer>
              <Close />
            </CloseContainer>
          ) : (
            <Search />
          )}
        </button>
      </SearchBoxContent>
    </SearchBoxContainer>
  );
};

export default SearchBox;
