import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const MainContainer = styled.div<{ color?: string }>`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  flex-direction: row;
  justify-content: center;
  min-height: 72px;

  span,
  p {
    color: ${({ color }) => color || 'black'};
  }

  .header-top-banner-first-section {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-family: ${fonts.instrumentSansBold};
    ${pxToCssFont(16, 22)}
    .after-logo-first-section {
      margin-right: 32px;
      font-family: ${fonts.instrumentSansBold};
      font-weight: 700;
    }
  }

  .header-top-banner-second-section {
    .after-logo-button-section {
      margin-right: 12px;
      font-family: ${fonts.instrumentSansBold};
      font-weight: 700;
    }
    display: flex;
    align-items: center;
    button {
      ${pxToCssFont(16, 22)}
      border-color: ${({ color }) => color || 'black'};
      padding-top: 13px;
      padding-bottom: 13px;
    }
    button:first-child {
      font-family: ${fonts.instrumentSansBold};
      color: ${({ color }) => color || 'black'};
      text-transform: lowercase;
      span::first-letter {
        text-transform: uppercase;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: 72px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const LogoContainer = styled.div`
  margin-right: 12px;
  height: 30px;
  img {
    object-fit: contain !important;
  }
`;
