import React, { SVGProps, memo } from 'react';

const User = (props: SVGProps<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 22 25">
    <path
      className="fill"
      fill="#0d0d0d"
      d="M.737 24a.5.5 0 1 0 1 0h-1Zm19.526 0a.5.5 0 1 0 1 0h-1ZM16.512 6.212h-.5.5ZM11 .7V.2v.5Zm5.512 0h.5V.2h-.5v.5ZM1.737 24c0-6.297 4.23-11.238 9.263-11.238v-1C5.25 11.762.737 17.33.737 24h1ZM11 12.762c5.033 0 9.263 4.94 9.263 11.238h1c0-6.67-4.513-12.238-10.263-12.238v1Zm5.012-6.55c0 .991-.294 1.96-.845 2.784l.832.556a6.011 6.011 0 0 0 1.013-3.34h-1Zm-.845 2.784a5.011 5.011 0 0 1-2.25 1.846l.384.924a6.01 6.01 0 0 0 2.697-2.214l-.83-.556Zm-2.25 1.846a5.012 5.012 0 0 1-2.895.285l-.195.98a6.012 6.012 0 0 0 3.474-.341l-.383-.924Zm-2.895.285a5.012 5.012 0 0 1-2.566-1.371l-.707.707a6.012 6.012 0 0 0 3.078 1.645l.195-.98ZM7.456 9.756a5.012 5.012 0 0 1-1.371-2.567l-.981.196a6.011 6.011 0 0 0 1.645 3.078l.707-.707ZM6.085 7.189a5.012 5.012 0 0 1 .285-2.895l-.924-.383a6.012 6.012 0 0 0-.342 3.474l.98-.196Zm.285-2.895a5.012 5.012 0 0 1 1.846-2.25l-.556-.83c-.989.66-1.759 1.599-2.214 2.697l.924.383Zm1.846-2.25A5.012 5.012 0 0 1 11 1.2v-1a6.012 6.012 0 0 0-3.34 1.013l.556.832ZM11 1.2h5.512v-1H11v1Zm5.012-.5v5.512h1V.7h-1Z"
    />
  </svg>
);

export default memo<SVGProps<SVGAElement>>(User);
