import { LogoWrapper, MainContainer } from './headerExtended.style';
import { IHeaderExtendedProps } from './headerExtended.models';
import { Wordmark } from '@vfit/shared-icons';

const HeaderExtended = ({ header }: IHeaderExtendedProps) => (
  <MainContainer id="extendedHeader">
    <LogoWrapper>
      <a href={header?.body?.headerLogo?.url}>
        <Wordmark />
      </a>
    </LogoWrapper>
  </MainContainer>
);

export default HeaderExtended;
