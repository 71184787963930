import React, { SVGProps, memo } from 'react';

const Marker = (props: SVGProps<SVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 14">
    <path
      fill="#BEBEBE"
      d="M11.5 5.667a.5.5 0 0 0-1 0h1ZM6 13.333l-.327.378a.5.5 0 0 0 .654 0L6 13.333ZM6 1.167a.5.5 0 0 0 0-1v1Zm-.022-1a.5.5 0 0 0 0 1v-1ZM10.5 5.689a.5.5 0 0 0 1 0h-1Zm-2.667-.022C7.833 6.679 7.013 7.5 6 7.5v1a2.833 2.833 0 0 0 2.833-2.833h-1ZM6 7.5a1.833 1.833 0 0 1-1.833-1.833h-1A2.833 2.833 0 0 0 6 8.5v-1ZM4.167 5.667c0-1.013.82-1.834 1.833-1.834v-1a2.833 2.833 0 0 0-2.833 2.834h1ZM6 3.833c1.013 0 1.833.821 1.833 1.834h1A2.833 2.833 0 0 0 6 2.833v1Zm4.5 1.834c0 .716-.272 1.527-.722 2.368-.447.834-1.05 1.66-1.662 2.395a24.745 24.745 0 0 1-2.431 2.515l-.01.008a.031.031 0 0 1-.002.002l.327.378.328.378v-.001c.002 0 .003-.002.004-.003l.012-.01a10.222 10.222 0 0 0 .208-.187 25.748 25.748 0 0 0 2.332-2.44c.638-.765 1.285-1.648 1.775-2.563.488-.91.841-1.89.841-2.84h-1ZM6 13.333c.327-.378.328-.377.328-.377l-.003-.003-.01-.008a5.743 5.743 0 0 1-.192-.173 24.745 24.745 0 0 1-2.238-2.342c-.613-.735-1.216-1.56-1.663-2.395-.45-.841-.722-1.652-.722-2.368h-1c0 .95.353 1.93.84 2.84.49.915 1.138 1.798 1.776 2.563a25.748 25.748 0 0 0 2.54 2.627l.012.01.003.003h.001v.001L6 13.333ZM1.5 5.667a4.5 4.5 0 0 1 1.318-3.182l-.707-.707A5.5 5.5 0 0 0 .5 5.667h1Zm1.318-3.182A4.5 4.5 0 0 1 6 1.167v-1a5.5 5.5 0 0 0-3.89 1.61l.708.708Zm3.16-1.318c.593 0 1.182.117 1.73.344l.383-.924a5.522 5.522 0 0 0-2.113-.42v1Zm1.73.344c.549.227 1.047.56 1.467.98l.708-.707A5.522 5.522 0 0 0 8.09.587l-.383.924Zm1.467.98c.42.42.753.919.98 1.467l.925-.382a5.522 5.522 0 0 0-1.197-1.792l-.708.707Zm.98 1.467c.228.55.345 1.137.345 1.731h1c0-.725-.143-1.443-.42-2.113l-.924.382Z"
    />
  </svg>
);

export default memo<SVGProps<SVGAElement>>(Marker);
