import React from 'react';
import { IHeaderTopBanner } from '@vfit/shared/models';
import { Button, ImageAtoms, Svg } from '@vfit/shared/atoms';
import { Colors } from '@vfit/shared/themes';
import { LogoContainer, MainContainer } from './topHeaderBanner.style';

const TopHeaderBanner: React.FC<IHeaderTopBanner> = ({
  textColor,
  logo,
  background,
  afterLogoLabel,
  beforeLogoLabel,
  actionLabel,
  actionVariant,
  actionUrl,
  isBlank,
}) => (
  <MainContainer
    color={textColor}
    style={{
      ...(background && { background }),
    }}
  >
    <div className="header-top-banner-first-section">
      {beforeLogoLabel && <span>{beforeLogoLabel}</span>}
      {logo && (
        <LogoContainer>
          <ImageAtoms nameCard="topBannerLogo" image={logo} />
        </LogoContainer>
      )}
    </div>
    <div className="header-top-banner-second-section">
      {afterLogoLabel && (
        <div className="after-logo-button-section">
          <span>{afterLogoLabel}</span>
        </div>
      )}
      {actionUrl && actionLabel && (
        <div className="button-section">
          <Button
            variant={actionVariant}
            onClick={() => {
              window.open(actionUrl, isBlank === 'true' ? '_blank' : '_self');
            }}
          >
            <span>{actionLabel}</span>
            <Svg name="arrowRight" width={24} color={textColor as Colors} />
          </Button>
        </div>
      )}
      {actionUrl && !actionLabel && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className="button-section"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            window.open(actionUrl, isBlank === 'true' ? '_blank' : '_self');
          }}
        >
          <Svg name="arrowRightFw" width={21} color={textColor as Colors} />
        </div>
      )}
    </div>
  </MainContainer>
);
export default TopHeaderBanner;
