import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  position: relative;
  border-top: 1px solid ${colors.$f2f2f2};
  display: flex;
  width: 100%;
`;

export const DropDownContent = styled.li`
  background-color: ${colors.$ffffff};
  width: 100%;

  a {
    color: #333 !important;
  }

  a:link {
    width: 100%;
    display: flex;
    color: #333;
  }

  a:hover,
  li:hover {
    color: ${colors.$e60000};
    text-underline-offset: 3px;
    background-color: #f0f0f0;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  padding: 0;
  margin: 0;
  padding-left: 137px;
  gap: 12px;
  background-color: white;
  width: 100%;

  /* stylelint-disable-next-line no-descending-specificity */
  li {
    display: flex;
    align-items: stretch;
    list-style: none;

    hovered {
      color: #333;
    }
  }

  a:link {
    position: relative;
    display: flex;
    height: 50px;
    align-items: center;
    text-decoration: none;
    color: ${colors.$7e7e7e};
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 400;
    ${pxToCssFont(18, 26)}
    padding: 0 24px;
  }

  a:visited {
    color: ${colors.$7e7e7e};
  }

  a:hover,
  li:hover {
    background-color: #f0f0f0;
    color: #333;
  }

  a:active {
    color: ${colors.$7e7e7e};
  }
`;

export const DropDownContainer = styled.ul`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  background-color: ${colors.$ffffff};
`;

